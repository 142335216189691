<template lang="pug">
    .editarPerfil
    
        .flex.justify-center.bg-medclub-blue-100.pt-10.pb-8.hidden(class='md:flex')
            .max-w-screen-lg
                h1.text-2xl.text-gray-700.text-center(class='md:text-4xl') Editar perfil

        .flex.justify-center.bg-medclub-blue-100
            .max-w-screen-lg.w-full
                .m-4
                    router-link.underline(:to="{ name: 'home' }") Início
                    i.jam.jam-chevron-right.mx-2.text-lg
                    span Editar perfil
                .flex.bg-white.p-4.shadow-md.justify-center.w-full(class='md:rounded md:mb-16')
                    form.mb-8.pt-5.w-full(class='md:w-1/2 md:pt-0' @submit.prevent='handleSubmit()')

                        b-field(label='Nome completo:' :message="model_message().nm_pessoa_fisica"
                            :type="{ 'is-danger': model.submitted && $v.model.nm_pessoa_fisica.$error }")
                            b-input(v-model='$v.model.nm_pessoa_fisica.$model' :disabled="!!usuario.nm_pessoa_fisica" rounded)

                        b-field(label='Como quer ser chamado?')
                            b-input(v-model='model.nm_apelido' rounded)
                        
                        b-field(label='Telefone:' :message="model_message().nr_telefone"
                            :type="{ 'is-danger': model.submitted && $v.model.nr_telefone.$error }")
                            masked-input.input.is-rounded(v-model='$v.model.nr_telefone.$model' type='tel' :mask="phoneMask"
                                :class="{ 'is-danger': model.submitted && $v.model.nr_telefone.$error }" :guide='false')
                        
                        b-field(label='CPF:' :message="model_message().nr_cpf"
                            :type="{ 'is-danger': model.submitted && $v.model.nr_cpf.$error }")
                            b-input(v-model='$v.model.nr_cpf.$model' type='tel' v-mask="'###.###.###-##'" :disabled="!!usuario.nr_cpf" rounded)
                        
                        b-field(label='Data de nascimento:' :message="model_message().dt_nascimento"
                            :type="{ 'is-danger': model.submitted && $v.model.dt_nascimento.$error }")
                            b-input(v-model='$v.model.dt_nascimento.$model' type='tel' v-mask="'##/##/####'" placeholder='dd/mm/aaaa' :disabled="!!usuario.dt_nascimento" rounded)

                        b-field(label='Sexo:' :message="model_message().ie_sexo"
                            :type="{ 'is-danger': model.submitted && $v.model.ie_sexo.$error }")
                            .columns.is-vcentered.is-mobile
                                .column(v-for='option in options.ie_sexo')
                                    b-radio(:key='option.value'
                                        v-model='$v.model.ie_sexo.$model'
                                        :native-value='option.value'
                                        :disabled="!!usuario.ie_sexo"
                                        name='sexo') {{ option.text }}
                            
                        .text-center
                            b-button.mt-8.w-full(native-type='submit' type='is-primary' rounded :loading='waiting.salvar') Atualizar
</template>

<script>
    import { required, minLength } from 'vuelidate/lib/validators'
    import { validateFone, phoneMask } from './../../utils'
    import moment from 'moment'; moment.locale('pt-br')
    import { Usuario } from '../../middleware'
    export default {
        created () {
            if (! this.$parent.$parent.usuario) this.$router.push({ name: 'home' })
            else {
                let usuario = JSON.parse(localStorage.getItem("MEDCLUBSITE_USER"));
                this.usuario = usuario

                this.model.nm_pessoa_fisica = usuario.nm_pessoa_fisica
                this.model.nr_telefone = this.$root.applyTelefoneMask(usuario.nr_telefone)
                this.model.nm_apelido = usuario.nm_apelido
                this.model.dt_nascimento = moment(usuario.dt_nascimento).format('DD/MM/YYYY')
                this.model.nr_cpf = usuario.nr_cpf
                this.model.ie_sexo = usuario.ie_sexo
            }
        },
        data () {

            const model = {
                nm_pessoa_fisica: null,
                nm_apelido: null,
                nr_telefone: null,
                dt_nascimento: null,
                nr_cpf: null,
                submitted: false,
                ie_sexo: null,
            }

            return {
                model, phoneMask,
                waiting: { salvar: false },
                usuario: null,
                options: {
                    ie_sexo: [
                        { value: 'M', text: 'Masculino' },
                        { value: 'F', text: 'Feminino' },
                    ],
                }
            }
        },
        validations () {
            const birth = moment(this.model.dt_nascimento, 'DD/MM/YYYY')
            const today = moment()
            const age =  today.diff(birth, 'years')
            
            let v = {
                model: {
                    nm_pessoa_fisica: {
                        required,
                        minLength: minLength(8),
                        fullName: val => val ? val.includes(' ') : false
                    },
                    nr_telefone: {
                        required,
                        validateFone
                    },
                    nr_cpf: age >= 18 ? {
                        required,
                        minLength:minLength(14)
                    } : {},
                    dt_nascimento: { 
                        required,
                        minLength: minLength(10),
                        dateValid: val => moment(val, 'DD/MM/YYYY').isValid() 
                    },
                    ie_sexo: {
                        required,
                    }
                }
            }
            return v
        },
        methods: {
            model_message () {

                let result = { nm_pessoa_fisica: '', nr_telefone: '', dt_nascimento: '' }

                if (this.model.submitted) {

                    if (! this.$v.model.nm_pessoa_fisica.required) result.nm_pessoa_fisica = 'Digite o nome'
                    else if (! this.$v.model.nm_pessoa_fisica.minLength) result.nm_pessoa_fisica = 'Nome deve conter pelo menos 8 caracteres'
                    else if (! this.$v.model.nm_pessoa_fisica.fullName) result.nm_pessoa_fisica = 'Digite o nome completo'

                    if (! this.$v.model.nr_telefone.required) result.nr_telefone = 'Digite o telefone'
                    else if (! this.$v.model.nr_telefone.validateFone) result.nr_telefone = 'Digite um telefone nacional válido'
                    
                    if (! this.$v.model.dt_nascimento.required) result.dt_nascimento = 'Digite a data de nascimento'
                    else if (! this.$v.model.dt_nascimento.minLength) result.dt_nascimento = 'Digite uma data válida'
                    else if (! this.$v.model.dt_nascimento.dateValid) result.dt_nascimento = 'Digite uma data válida'

                    if (! this.$v.model.ie_sexo.required) result.ie_sexo = 'Escolha o sexo'
                }
                return result

            },
            handleSubmit () {

                this.model.submitted = true
                this.$v.$touch(); if (this.$v.$invalid) return 0
                
                this.waiting.salvar = true
                
                let dataSend = Object.assign({}, this.model)
                let clean = ['submitted']
                clean.forEach(i => delete dataSend[i])

                dataSend.dt_nascimento = moment(dataSend.dt_nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD')
                let onlyNumbers = ['nr_telefone']
                onlyNumbers.forEach(i => { if (dataSend[i]) dataSend[i] = dataSend[i].match(/\d+/g).join('') })

                dataSend.nr_telefone = `+55${ dataSend.nr_telefone }`

                dataSend.nr_cpf = dataSend.nr_cpf ? dataSend.nr_cpf.match(/\d+/g).join('') : null

                dataSend.id = this.$parent.$parent.usuario.id

                Usuario.save(dataSend).then(response => {
                
                    this.waiting.salvar = false

                    if ([200, 201, 204].includes(response.status)) {

                        let usuario = JSON.parse(localStorage.getItem('MEDCLUBSITE_USER'))
                        usuario.dt_nascimento = response.data.dt_nascimento
                        usuario.nm_pessoa_fisica = response.data.nm_pessoa_fisica
                        usuario.nr_telefone = response.data.nr_telefone
                        usuario.first_name = usuario.nm_pessoa_fisica.split(' ')[0]
                        usuario.nm_apelido = response.data.nm_apelido
                        usuario.nr_cpf = response.data.nr_cpf
                        usuario.ie_sexo = response.data.ie_sexo
                        
                        localStorage.setItem('MEDCLUBSITE_USER', JSON.stringify(usuario))
                        this.$parent.$parent.usuario = usuario

                        this.$buefy.toast.open({
                            type: 'is-success', position: 'is-bottom',
                            message: 'Cadastro <b>atualizado</b>'
                        })
                        this.model.submitted = false

                    } else { 
                        if (response.status === 400) {
                            if (typeof response.data === 'object') {
                                Object.values(response.data).forEach(message => {
                                    this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', duration: 4000, message: message })
                                })
                            }
                        }
                    }
                })
            }
        }
    }
</script>