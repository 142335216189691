import _ from 'lodash'

const validateCpf = (cpf) => {
  if (!cpf) return false;
  if (cpf.includes(".")) {
    cpf = cpf.split(".").join("");
    cpf = cpf.split("-").join("");
  }
  let Soma = 0;
  let Resto;
  if (cpf == "00000000000") return false;
  for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;
  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(cpf.substring(9, 10))) return false;
  Soma = 0;
  for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;
  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(cpf.substring(10, 11))) return false;
  return true;
};

function mergeArray(array, key) {
  const marged = Array.from(
    array.reduce((entryMap, e) => entryMap.set(e[key], { ...(entryMap.get(e[key]) || {}), ...e }), new Map()).values()
  );

  return marged;
}

const validateFone = (fone) => {
  if (!fone) return false;
  fone = fone.match(/\d+/g).join("");
  return fone.length === 10 || fone.length === 11;
};

const phoneMask = (value) => {
  let check = false;
  if (value) if (value.match(/\d+/g)) check = true;

  if (!check) return [];
  else
    return value.match(/\d+/g).join("").length < 11
      ? ["(", /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/]
      : ["(", /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
};

const creditCardMask = (value) => {
  if (!value) return [];
  else
    return parseInt(value[0]) === 3
      ? [/\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/]
      : [/\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/];
};

const integerCurrencyMask = (value) => {
  if (!value) return [];
  else return ["R", "$", " ", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
};

const isIOS = () =>
  ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
  (navigator.userAgent.includes("Mac") && "ontouchend" in document);

const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const hasNumber = (str) => /\d/.test(str);

const normaliza = (texto) => texto.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
const ordemBusca = (lista, texto) => {
  _.forEach(lista, l => {
      switch (true) {
          case _.startsWith(l['nm_especialidade'], texto):
              l['nr_ordem'] = 0;        
              break;
          case _.startsWith(normaliza(l['nm_especialidade']), normaliza(texto)):
              l['nr_ordem'] = 1;        
              break;
          case _.includes(l['nm_especialidade'], texto):
              l['nr_ordem'] = 2;        
              break;
          case _.includes(normaliza(l['nm_especialidade']), normaliza(texto)):
              l['nr_ordem'] = 3;
              break;
          default:
              l['nr_ordem'] = 4
              break;
      }
  });
  return _.sortBy(_.filter(lista, function(o) { return o.nr_ordem != 4 }), ['nr_ordem', 'nm_especialidade'])
}


export const ONLY_NUMBERS_REGEX = /\D/g;
export default function onlyNumbers(value) {
  return value.replace(ONLY_NUMBERS_REGEX, '');
}

export {
  validateCpf,
  validateFone,
  phoneMask,
  creditCardMask,
  integerCurrencyMask,
  mergeArray,
  isIOS,
  isMobileDevice,
  hasNumber,
  normaliza,
  ordemBusca,
  onlyNumbers
};
